import React, { useContext, useEffect, useRef } from 'react';
import { gsap, ScrollTrigger} from "gsap/all";
import AnimationContext from '../../contexts/animation-context';

export default function AboutUs() {

    let secondRef = useRef(null);
  let textRef = useRef(null);
  let animationContext = useContext(AnimationContext);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: secondRef.current,
        start: "+=200 70%",
        end: "+=200 60%",
        scrub: true,
        markers: false,
        pinSpacing: false,
        onEnter: () => {
          animationContext.setCurrentBg('#0a0a0a');
          gsap.to(textRef.current, {
            color: '#fff',
            duration: 0.4
          })
        },
        onLeaveBack: () => {
          animationContext.setCurrentBg('#f5f0ed');
          gsap.to(textRef.current, {
            color: '#000',
            duration: 0.6
          })
          
        }
      }
    })
  }, [])
    return(
        <div ref={secondRef} className="aboutus" >
            <div className='container-aboutus'>
                <p ref={textRef} className='p-aboutus'>Siamo Alessandro e Alessio, due ragazzi appassionati di informatica e pronti a sviluppare qualunque applicazione!Siamo Alessandro e Alessio, due ragazzi appassionati di informatica e pronti a sviluppare qualunque applicazione!Siamo Alessandro e Alessio, due ragazzi appassionati di informatica e pronti a sviluppare qualunque applicazione!</p>
            </div>
        </div>
    )
}
