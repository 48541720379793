
import React, { useEffect, useRef } from 'react';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);
export default function Hero() {
    return (
        <div className="header">
            <h1 className='hero-text'>DefralCoding</h1>
        </div>
    )
}