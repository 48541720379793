
import React, { useContext, useEffect, useRef } from 'react';
import { gsap, ScrollTrigger } from "gsap/all";
import AnimationContext from '../../contexts/animation-context';
import {WorkCard} from '../../components/WorkCard';
export default function Works() {
    let thirdRef = useRef(null);
    let textRef = useRef(null);
    let animationContext = useContext(AnimationContext);
    gsap.registerPlugin(ScrollTrigger);
    
    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
              trigger: thirdRef.current,
              start: "+=20 70%",
              end: "+=20 60%",
              scrub: true,
              markers: false,
              pinSpacing: false,
              onEnter: () => {
                animationContext.setCurrentBg('#002A40');
                gsap.to(textRef.current, {
                  color: '#fff',
                  duration: 0.4
                })
              },
              onLeaveBack: () => {
                animationContext.setCurrentBg('#0a0a0a');
                gsap.to(textRef.current, {
                  color: '#fff',
                  duration: 0.6
                })
              }
            }
          })
      }, [])

      
    return(
        <div ref={thirdRef} className="works">
            <div className='container-works'>
                <h1 className='p-works'>Our Works</h1>
                <div ref={textRef} className='works-cards'>
                    <WorkCard/>
                    <WorkCard/>
                    <WorkCard/>
                    <WorkCard/>
                </div>
            </div>
        </div>
    )
}