import {NavBar} from './components/NavBar';
import {Footer} from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./App.css';
import "animate.css/animate.min.css";
import React, { useEffect, useRef, useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import {gsap} from 'gsap/all'
import AnimationContext from './contexts/animation-context';

import Hero from './pages/Hero';
import AboutUs from './pages/AboutUs';
import Works from './pages/Works';


function App() {
  const [currentBg, setCurrentBg] = useState('#f5f0ed')
  let appRef = useRef(null);
  useEffect(() => {
    gsap.to(appRef.current, {
      duration: 0.5,
      background: currentBg,
    })
  }, [currentBg]);
  
  return (
  <>
    <AnimationContext.Provider value={{ setCurrentBg }}>
      <div ref={appRef}>
        <NavBar />
        <Hero/>
        <AboutUs/>
        <Works/>
        <Footer/>
      </div>
    </AnimationContext.Provider>
    
  </>
  );
}

export default App;
