import {Navbar,Container,Nav} from "react-bootstrap";
import React, { useState, useEffect } from "react"

export const WorkCard = () => {
    return(
        <div className="work-card">
            <img className="work-image" alt="work-image" src=""/>
            <div className="work-text">
                <p className="work-title">Elrond Bulk</p>
                <p className="work-description">Breve descrizione del lavoro....</p>
            </div>
        </div>
    );
}