import {Navbar,Container,Nav} from "react-bootstrap";
import React from 'react';
import daclogo from '../assets/img/dac-header.svg';

export const NavBar = () => {
 
    return(
        <div className="mx-4 my-4 fixed-top">
          <img className="logo-header" src={daclogo}/>
        </div>
    );
}
